import React from 'react';
import { connect } from 'react-redux';
import { Row, Skeleton, Spin, Tooltip } from 'antd';
import { ComponentText } from '../../shared/ComponentText';
import { ComponentIcon } from '../../shared/ComponentIcon';
import { ComponentButton } from '../../shared/ComponentButton';
import { loadData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { contentType } from '../../../utils/ActionTypes';
import {
  appSecretListPath,
  productListPath,
} from '../../../utils/routerUtils';
import { fonts } from '../../../utils/fonts';
import { ComponentCopyButton } from '../../shared/ComponentCopyButton';
import listUtils from '../../../utils/listUtils';
import Space from '../../shared/Spcae';
import images from '../../../images/Images';

class ConnectSDK extends React.Component {

	intervalID;

	constructor (props) {
	  super(props);
	  this.state = {key:"", secret:"", sdkSynced: false, syncStarted: false};
	}

	componentDidMount() {
	  const { onLoadData, currentApp } = this.props;

	  onLoadData(contentType.APP_SECRET_LIST, appSecretListPath, {
	    filters: { application_id: currentApp.id },
	    pager: { skip: 0, limit: 5 },
	  });

	  this.intervalID = setInterval(this.getData.bind(this), 10000);
	  this.getData();
	}
	componentWillUnmount() {
	  clearInterval(this.intervalID);
	}
	getData = () => {
	  const { onLoadData, currentApp, currentStep } = this.props;
	  if(currentStep === 7)
	  	return;
	  let filters = { application_id: currentApp.id };
	  onLoadData(contentType.PRODUCT_LIST, productListPath, listUtils.prepareActionParams(1, 10, filters) );
	};

	componentDidUpdate (prevProps, prevState, snapshot) {


	  if (this.props.list && prevProps.list !== this.props.list && this.props.list.length > 0) {
		  this.setState({ key: this.props.list[0].api_key , secret: this.props.list[0].api_secret });
	  }
	  const {productListData} = this.props;
	  if(productListData && productListData !== prevProps.productListData){
	    for(let i = 0;i< productListData.length; i++){
	    	if(productListData[i].status !== "STATUS_READY" || productListData[i].status !== "STATUS_ACTIVE"){
	    		this.setState({sdkSynced: true});
	      }
	    }
	  }
	  // if(this.state.syncStarted === true && this.state.sdkSynced === false){
	  //   this.setState({sdkSynced: true});
	  // }

	}

	render() {
    	const {isFetching} = this.props;

    	return (

    		<div style={{width: 530}}>
    			<Row type="flex">
    				<ComponentText text="Connect SDK" size="20" color= "var(--black)" font={fonts.SFProDisplaySemiBold}/>
    				<Tooltip contentStyle={{backgroundColor: '#4A4A4A'}} placement="bottomLeft" title={"Products Groups represent a App Store Connect group of products. Please arrange them how you arranged in App Store Connect."}>
    					<a style={{paddingLeft: 10,
    						height: 20}}><ComponentIcon type="help" width={15}/></a>
    				</Tooltip>
    			</Row>
    			<div className="on-board-connect-sdk">
    				<ComponentText text="API Key" size="13" font={fonts.SFProDisplayMedium} color="var(--greyish-brown)" align="left"/>
    				<Skeleton loading={isFetching} active paragraph={{rows: 0}}title={{width: 480}}>
    					<Row type="flex" className={"key"} justify={"space-between"} align={"middle"} >
    						<ComponentText text={this.state.key} font={fonts.SFProDisplaySemiBold} size="15" color="var(--slate-grey)" align="left"/>
	            <ComponentCopyButton data={this.state.key} size={20}/>

    					</Row>
    				</Skeleton>

    				<ComponentText text="Secret Key" size="13" font={fonts.SFProDisplayMedium} color="var(--greyish-brown)" align="left"/>
    				<Skeleton loading={isFetching} active paragraph={{rows: 0}}title={{width: 480}}>
    					<Row type="flex" className={"key"} justify={"space-between"} align={"middle"} >
    						<ComponentText text={this.state.secret} size="15" font={fonts.SFProDisplaySemiBold} color="var(--slate-grey)" align="left"/>
	            <ComponentCopyButton data={this.state.secret} size={20}/>

    					</Row>
    				</Skeleton>

    			</div>

	      {this.state.sdkSynced !== true ?<div className="on-board-connect-sdk">
    				<Row type="flex" align="middle" justify="space-between">
	          <Row type="flex" align="middle" >
	            <img src={images.dismiss} width={36}/>
	            <Space width={12}/>
    					<ComponentText text="Not connected" font={fonts.SFProDisplayRegular} size="15" color="var(--slate-grey)" align="left"/>
	          </Row>
	          {this.state.syncStarted === false ? <ComponentButton name="connect" text="Connect SDK Now" css="purchase-subscription" width="195" height="42" handleClick={()=>{
	            // this.intervalID = setInterval(this.getData.bind(this), 10000);
	            // this.getData();
	            this.setState({syncStarted: true});
	          }}/> : <Spin/>}
    				</Row>
    			</div>:
	      <div>
    			<div className="on-board-connected">
    				<Row type="flex" align="middle" justify="center">
	              <ComponentIcon type={"check"} width={18}/>
	              <Space width={10}/>
    					<ComponentText text="SDK connected successfully" font={fonts.SFProDisplaySemiBold} size="15" color="var(--dark-mint)" align="left" marginTop="0"/>
    				</Row>
    			</div>

    			<Row type="flex" align="middle" justify="end">
    				<ComponentButton name="connect" text="Proceed" css="purchase-subscription" width="125" height="42" handleClick={()=>{
    					this.componentWillUnmount();
    				this.props.onNext();}}/>

    			</Row>
	      </div>}


    		</div>

    	);
	}

}


const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const {appSecretListRemoteData} = state;
  return {
	  list: utils.getData(appSecretListRemoteData),
	  isFetching: utils.getIsFetching(appSecretListRemoteData),
	  isFailed: utils.getIsFailed(appSecretListRemoteData),
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(ConnectSDK);
