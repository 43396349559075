import React from 'react';
import { connect } from 'react-redux';
import { ComponentText } from '../../shared/ComponentText';
import listUtils from '../../../utils/listUtils';
import { loadData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import Space from '../../shared/Spcae';
import { tableContentType } from '../../shared/Table/TableDetails';
import { contentType } from '../../../utils/ActionTypes';
import { getBillingReceiptListPath } from '../../../utils/routerUtils';
import { TableListNew } from '../../shared/Table/TableListNew';
import _ from 'lodash';
import { fonts } from '../../../utils/fonts';
import ComponentPaymentMethod from './ComponentPaymentMethod';
import { getUser } from '../../../utils/Repository';

class ComponentInvoices extends React.Component {

  componentDidMount() {
    const {onLoadData} = this.props;

    let page = listUtils.currentPage(this.props.location.search);
    let limit = listUtils.currentLimit(this.props.location.search);
    const user = getUser();
    onLoadData(
      contentType.BILLING_RECEIPT_LIST,
      getBillingReceiptListPath,
      listUtils.prepareActionParams(page, limit, {organization_id: user.organizations_roles[0].organization_id })
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let page = listUtils.currentPage(this.props.location.search);
    let limit = listUtils.currentLimit(this.props.location.search);

    let previousPage = listUtils.currentPage(prevProps.location.search);
    let previousLimit = listUtils.currentLimit(prevProps.location.search);

    if (page !== previousPage || limit !== previousLimit) {
      this.componentDidMount();
    }
  }

  loadListWithPageFilter() {
    const {onLoadData} = this.props;
    let page = listUtils.currentPage(this.props.location.search);
    let filters = listUtils.prepareFilterParamsWithDate(
      listUtils.prepareFilterParams(
        listUtils.currentFilters(
          this.props.location.search,
          //listQueryFilterParams()
        )
      ),
      this.state.between
    );
    onLoadData(
      listUtils.prepareActionParams(page, filters),
      //boilerplateListPath,
      //contentType.BOILERPLATE_LIST
    );
  }

  render() {
    let {list, isFetching, isFailed, pager} = this.props;

    const invoices = list && list.length > 0 ? list.map((e)=>{
      return {
        id: e.id,
        purchased_at: e.purchased_at,
        plan: `${e.product.name} (${this.getPlan(e.product, e.product_id)})`,
        price: "$" + (isFloat(this.getPrice(e)) === true? this.getPrice(e).toFixed(2): this.getPrice(e)),
        status: e.status,
        invoice_action: this.getPrice(e) === 0 ? '': e.status === 'STATUS_UNPAID' ? 'ACTION_PAY' : 'ACTION_VIEW',
        product: e,
        actions: [],
      };
    }) : [];

    return <div className="invoice">
      <ComponentText text="Invoice List" size="24" color= "var(--black)" font={fonts.SFProDisplaySemiBold}/>

      <Space height={30}/>
      {/*<Row type="flex" justify="space-between" align="middle">*/}
      {/*  <ComponentText text="Invoice List" size="18" color="var(--black)" align="left" marginTop="0" marginBottom="0"/>*/}
      {/*  <Input.Search allowClear*/}
      {/*    placeholder="input search text"*/}
      {/*    onSearch={value => console.log(value)}*/}
      {/*    style={{width: 200,*/}
      {/*      marginRight: 8}}*/}
      {/*  />*/}
      {/*</Row>*/}
      <Space height="14"/>
      <TableListNew
        history={this.props.history}
        location={this.props.location}
        isLastPage = {!pager}
        list={invoices}
        isFetching={isFetching}
        isFailed={isFailed}
        attributes={attributes}
      />
      <ComponentPaymentMethod/>
    </div>;
  }

    getPlan = (product, product_id) => {
      let p = product.subscription_plans.find(e => e.product_id === product_id);
      return p.type.replace("SubscriptionPlanType", "");
    };
    getPrice = (invoice) => {

      const overage = invoice.overage_cost ? Math.round(invoice.overage_cost) : 0;

      switch (invoice.type) {
      case 'TYPE_BOOKING':
      case 'TYPE_PRODUCTION':
        return invoice.discount_price + overage;
      case 'TYPE_OVERAGE':
        return invoice.original_price + overage;
      default:
        return 0;
      }

    };
}


const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const {billingReceiptListRemoteData} = state;
  return {
    list: _.cloneDeep(utils.getData(billingReceiptListRemoteData)),
    pager: utils.getPager(billingReceiptListRemoteData),
    isFetching: utils.getIsFetching(billingReceiptListRemoteData),
    isFailed: utils.getIsFailed(billingReceiptListRemoteData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(ComponentInvoices);


const attributes = [
  {
    title: '',
    field: 'empty',
    type: tableContentType.EMPTY,
  },
  {
    title: 'ID',
    field: 'id',
    type: tableContentType.TEXT,
  },
  {
    title: 'Date',
    field: 'purchased_at',
    type: tableContentType.DATE_TIME_INVOICE,
  },
  {
    title: 'Plan',
    field: 'plan',
    type: tableContentType.TEXT,
  },
  {
    title: 'Ammount',
    field: 'price',
    type: tableContentType.REVENUE,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.INVOICE_STATUS,
  },
  {
    title: '',
    field: 'invoice_action',
    type: tableContentType.INVOICE_ACTION,
  },
  {
    title: '',
    field: 'action',
    type: tableContentType.ACTION,
  },
];

function isFloat(x) { return !!(x % 1); }
