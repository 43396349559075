
import {
  ADMIN_LOGGED_IN,
  ADMIN_LOGGED_OUT,
  ADMIN_LOGIN_FAIL, ADMIN_LOGIN_FAIL_ORG_NAME,
  ADMIN_LOGIN_REQUEST,
  ADMIN_REGISTER_REQUEST,
  RESET_PASS_SUCCESS,
} from './authActionTypes';
import { localStorageKey } from '../../utils/LocalStorage';
import { createReducerWraper } from '../../utils/Reducer';
import { contentType } from '../../utils/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';
import { FAILED, RECEIVE, REQUEST } from '../../utils/ActionCreators';

// export const authReducer = createReducer(
//   {},
//   {
//     [ADMIN_LOGIN_REQUEST]: (state, action) => {
//       return Object.assign({}, state, {
//         user: null,
//         resetPassword: false,
//         isAuthenticating: true,
//         isAuthenticated: false,
//         isAuthFailed: false,
//         token: null,
//         userName: null,
//         message: null,
//       });
//     },
//     [ADMIN_REGISTER_REQUEST]: (state, action) => {
//       return Object.assign({}, state, {
//         user: null,
//         resetPassword: false,
//         isAuthenticating: true,
//         isAuthenticated: false,
//         isAuthFailed: false,
//         token: null,
//         userName: null,
//         message: null,
//       });
//     },
//     [ADMIN_LOGGED_IN]: (state, action) => {
//       localStorage.setItem(localStorageKey.AUTH_TOKEN, action.payload.token.access_token);
//       const user = action.payload.user;
//       let userOb = user;
//       if (typeof user === 'string' || user instanceof String) {
//         localStorage.setItem(localStorageKey.USER, user);
//         userOb = JSON.parse(user);
//       }
//       else
//         localStorage.setItem(localStorageKey.USER, JSON.stringify(user));
//
//       return Object.assign({}, state, {
//         isFetching: false,
//         response: action.payload,
//         isFailed: false,
//         message: "You have been successfully logged in.",
//       });
//     },
//     [ADMIN_LOGIN_FAIL]: (state, action) => {
//       localStorage.removeItem(localStorageKey.AUTH_TOKEN);
//       return Object.assign({}, state, {
//         user: null,
//         resetPassword: false,
//         isAuthenticating: false,
//         isAuthenticated: false,
//         isAuthFailed: true,
//         token: null,
//         userName: null,
//         message: action.payload.message,
//       });
//     },
//     [ADMIN_LOGGED_OUT]: (state, action) => {
//       localStorage.removeItem(localStorageKey.AUTH_TOKEN);
//       return Object.assign({}, state, {
//         user: null,
//         resetPassword: false,
//         isAuthenticating: false,
//         isAuthenticated: false,
//         isAuthFailed: false,
//         token: null,
//         userName: null,
//         message: "You have been logged out",
//       });
//     },
//
//     [ADMIN_LOGIN_FAIL_ORG_NAME]: (state, action) => {
//       return Object.assign({}, state, {
//         user: null,
//         resetPassword: false,
//         loginNeeded: true,
//         resetPasswordEmail: '',
//         isAuthenticating: false,
//         isAuthenticated: false,
//         isAuthFailed: false,
//         token: null,
//         userName: null,
//         message: "You have been logged out",
//       });
//     }
//   }
// );

export const loginReducer =  createReducer(
  {},
  {
    [REQUEST + contentType.LOGIN.name]: (state, action) => {
      return Object.assign({}, state, {
        isFetching: true,
        response: null,
        isFailed: false,
        message: null,
      });
    },
    [RECEIVE + contentType.LOGIN.name]: (state, action) => {
      if (typeof action.payload === 'string' || action.payload instanceof String) {
        localStorage.setItem(localStorageKey.AUTH_RESPONSE, action.payload);
      }
      else
        localStorage.setItem(localStorageKey.AUTH_RESPONSE, JSON.stringify(action.payload));

      return Object.assign({}, state, {
        isFetching: false,
        response: action.payload,
        isFailed: false,
        message: null,
      });
    },
    [FAILED + contentType.LOGIN.name]: (state, action) => {
      return Object.assign({}, state, {
        isFetching: false,
        response: null,
        isFailed: true,
        message: action.payload.message ? action.payload.message:  action.payload,
      });
    },
  }
);


//export const loginReducer = createReducerWraper(contentType.LOGIN);

export const signUpReducer = createReducerWraper(contentType.SIGN_UP);
export const signUpGoogleReducer = createReducerWraper(contentType.SIGN_UP_GOOGLE);
export const logoutReducer = createReducerWraper(contentType.INVALID_ACCESS_TOKEN);
export const resendEmailReducer = createReducerWraper(contentType.RESEND_EMAIL);
export const forgetPasswordReducer = createReducerWraper(contentType.FORGOT_PASSWORD);
export const resetPasswordReducer = createReducerWraper(contentType.RESET_PASSWORD);
export const verifyUserReducer = createReducerWraper(contentType.VERIFY_USER);
