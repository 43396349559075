import React from 'react';
import { message } from 'antd';
import { ComponentButton } from './ComponentButton';

export class ComponentCopyButton extends React.Component {

    onButtonClick = () =>{
    	navigator.clipboard.writeText(this.props.data);
    	success('"' + this.props.data + '"' + " copied to clipboard");
    };
    render() {
    	const {size} = this.props;
    	return <ComponentButton  htmlType="button" name="copy" icon="copy" text="" css="icon-only" iconSize={size ? size : 14} handleClick={this.onButtonClick}/>;
    }
}

const success = (text) => {
  message.success(text);
};
