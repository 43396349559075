import React from 'react';
import { connect } from 'react-redux';
import { Empty, Row } from 'antd';
import listUtils from '../../../utils/listUtils';
import { listQueryFilterParams } from './AlertListConstants';
import '../Alert.css';
import {
  alertCreatePath,
  getAlertListPath,
  getAlertUpdate, getAlertUpdatePath,
} from '../../../utils/routerUtils';
import { loadData } from '../../../utils/ActionCreators';
import { contentType } from '../../../utils/ActionTypes';
import { Link } from 'react-router-dom';
import { ComponentText } from '../../shared/ComponentText';
import utils from '../../../utils/utils';
import { ComponentButton } from '../../shared/ComponentButton';
import image from '../../../images/empty-state-illustration@3x.png';
import { TableListNew } from '../../shared/Table/TableListNew';
import { tableContentType } from '../../shared/Table/TableDetails';
import { getUser } from '../../../utils/Repository';
import ComponentAppSelect from '../../shared/ComponentAppSelect';
import { fonts } from '../../../utils/fonts';
import queryString from 'query-string';

const path = getAlertListPath;
const title = 'Alerts';
const subtitle = 'Alerts';
const enableCreate = true;
const createPath = alertCreatePath;

class AlertList extends React.Component {

  componentDidMount () {
    this.loadListWithPageFilter();
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const currentSearch = this.props.location.search;
    const previousSearch = prevProps.location.search;
    if (currentSearch !== previousSearch) {
      const values = queryString.parse(this.props.location.search);
      if(values["application_ids"]){
        this.loadListWithPageFilter();
      }else{
        this.componentDidMount();
      }
    }
  }

  loadListWithPageFilter () {
    const { onLoadData } = this.props;
    let page = listUtils.currentPage(this.props.location.search);
    let limit = listUtils.currentLimit(this.props.location.search);

    let filters =  listUtils.currentFilters(this.props.location.search, listQueryFilterParams());

    const user = getUser();
    filters['organization_id'] = user.organizations_roles[0].organization_id;
    if(filters['application_ids'] !== 'all-app') {
      filters['application_id'] = filters['application_ids'];
    }
    delete filters['application_ids'];
    onLoadData(
      contentType.ALERT_LIST,
      path,
      listUtils.prepareActionParams(page, limit, filters),
    );
  }

    prepareEmptyView = () => {
      return <Empty
        image={image}
        imageStyle={{
          marginTop: '48px',
          height: 332,
        }}
        description=""
      >
        <div>
          <ComponentText
            text={'Nothing in here'}
            size="24"
            weight={600}
            color="var(--greyish-brown)"
            marginBottom={7}
          />
          <ComponentText
            text={'You don’t have any alerts created yet'}
            size="22"
            weight={400}
            color="var(--cool-grey)"
            marginBottom={28}
          />
          <Link key="create" to={'create'}>
            <ComponentButton
              name="create"
              iconRight="set-up-app"
              text="Set up your app"
              css="dark-sky-blue eina-semibold-dark-12"
              width="206"
              height="42"
              iconSize="20px"
              spaceWidth={32}
              stylText={{
                fontSize: '14px',
                fontFamily: 'SFProDisplay-Semibold',
                lineHeight: 1.43,
                color: 'var(--white)',
              }}
            />
          </Link>
        </div>
      </Empty>;
    };

    render () {

      let { pager, list, isFetching, isFailed, appList } = this.props;

      const alertList = appList && list && list.length > 0 ? list.map((e) => {
        return {
          id: e.id,
          name: e.name,
          app_name: getAppName(appList, e.application_id),
          when: `${getCriteria(e.criteria.name)} ${getRelation(
            e.criteria.relation)} ${utils.formatNumber(
            e.criteria.value)}`,
          frequency: `${getInterval(e.schedule.interval)}`,
          channels: `${getChannels(e.alert_type)}`,
          color: getAppColor(appList, e.application_id),
          go_back: true,
          edit: getAlertUpdatePath(e.id),
        };
      }) : [];

      return (
        <div className={'alert-list'}>

          <Row type="flex" justify="space-between" align="middle" style={{ marginBottom: 28}}>
            <ComponentText text={title} size="24"
              font={fonts.SFProDisplaySemiBold}
              color="var(--black-two)" align="left"
              marginTop="0" marginBottom="0"/>
            <ComponentAppSelect location={this.props.location}
              history={this.props.history}
              border={true}/>
          </Row>

          {list && list.length !== 0 && <Row type="flex" justify="end" align="middle" style={{ marginBottom: 10}}>
            <Link key="create" to={createPath}>
              <ComponentButton
                name="create"
                iconLeft="add"
                text="New Alert"
                height="42"
                css="add-new-app"
                iconSize="12px"
              />
            </Link>
          </Row>}

          {list && list.length === 0 && isFetching === false ?
            this.prepareEmptyView() :
            <TableListNew
              history={this.props.history}
              location={this.props.location}
              isLastPage={!pager}
              list={alertList}
              isFetching={isFetching}
              isFailed={isFailed}
              attributes={attributes}
            />}
        </div>
      );
    }

	prepareEmptyView = () => {
	  return <Empty
	    image={image}
	    imageStyle={{
	      marginTop: '48px',
	      height: 332,
	    }}
	    description=""
	  >
	    <div>
	      <ComponentText
	        text={'Nothing in here'}
	        size="24"
	        weight={600}
	        color="var(--greyish-brown)"
	        marginBottom={7}
	      />
	      <ComponentText
	        text={'You don’t have any alerts created yet'}
	        size="22"
	        weight={400}
	        color="var(--cool-grey)"
	        marginBottom={28}
	      />
	      <Link key="create" to={'create'}>
	        <ComponentButton
	          name="create"
	          iconRight="set-up-app"
	          text="Create an Alert"
	          css="dark-sky-blue eina-semibold-dark-12"
	          width="206"
	          height="42"
	          iconSize="20px"
	          spaceWidth={32}
	          stylText={{
	            fontSize: '14px',

	            fontFamily: 'SFProDisplay-Semibold',
	            lineHeight: 1.43,
	            color: 'var(--white)',
	          }}
	        />
	      </Link>
	    </div>
	  </Empty>;
	};
}

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const { alertListRemoteData, appsListRemoteData } = state;
  return {
    appList: utils.getData(appsListRemoteData),
    list: utils.getData(alertListRemoteData),
    pager: utils.getPager(alertListRemoteData),
    isFetching: utils.getIsFetching(alertListRemoteData),
    isFailed: utils.getIsFailed(alertListRemoteData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps,
)(AlertList);

const attributes = [
  {
    title: '',
    field: 'indicator',
    type: tableContentType.INDICATOR,
  },
  {
    title: 'Alert Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'When',
    field: 'when',
    type: tableContentType.TEXT,
  },
  {
    title: 'Frequency',
    field: 'frequency',
    type: tableContentType.TEXT,
  },
  {
    title: 'Channel(s)',
    field: 'channels',
    type: tableContentType.TEXT,
  },
  {
    title: 'App',
    field: 'app_name',
    type: tableContentType.APP_NAME,
  },
  {
    title: '',
    field: 'edit',
    type: tableContentType.ALERT_ACTION,
  },
  {
    title: '',
    field: '',
    type: tableContentType.EMPTY,
  },
];

function getRelation (relation) {
  switch (relation) {
  case 'RELATION_ABOVE':
    return '>';
  case 'RELATION_BELOW':
    return '<';
  default:
    return '?';
  }
}

function getCriteria (criteria) {
  switch (criteria) {
  case 'CRITERIA_REVENUE':
    return 'Revenue';
  case 'CRITERIA_SUBSCRIBER':
    return 'Subscriber';
  default:
    return 'Unknown';
  }
}

function getInterval (interval) {
  switch (interval) {
  case 'INTERVAL_DAILY':
    return 'Daily';
  case 'INTERVAL_WEEKLY':
    return 'Weekly';
  case 'INTERVAL_MONTHLY':
    return 'Monthly';
  case 'INTERVAL_YEARLY':
    return 'yearly';
  default:
    return 'Unknown';
  }
}

function getChannel (channel) {
  switch (channel) {
  case 'ALERT_TYPE_EMAIL':
    return 'Email';
  case 'ALERT_TYPE_WEBHOOK':
    return 'Webhook';
  case 'ALERT_TYPE_SMS':
    return 'SMS';
  case 'ALERT_TYPE_SLACK':
    return 'Slack';
  default:
    return 'Unknown';
  }
}

function getChannels (alertType) {

  let res = '';

  for (let i = 0; i < alertType.length; i++) {
    if (i === 0) {
      res += getChannel(alertType[i].name);
    } else {
      res += `, ${getChannel(alertType[i].name)}`;
    }
  }
  return res;
}

function getAppName (appList, application_id) {

  const app = appList.find(e => e.id === application_id);
  return app.name;

}

function getAppColor (appList, application_id) {
  const app = appList.find(e => e.id === application_id);
  return app.color;
}




