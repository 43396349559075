import React from 'react';
import { Form, Input } from 'antd';

export class PasswordComponent extends React.Component {
  render() {
    const {getFieldDecorator} = this.props.form;
    const {name, label, placeholder, rules} = this.props.values;

    return (
      <Form.Item className="no-bottom-margin" label={label}>
        {getFieldDecorator(name, {
          rules: rules,
        })(<Input.Password placeholder={placeholder} />)}
      </Form.Item>
    );
  }
}
