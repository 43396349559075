import React from 'react';
import { connect } from 'react-redux';
import { Col, Form, Icon, message, Row, Skeleton, Spin, Upload } from 'antd';
import styled from 'styled-components';
import { ComponentText } from '../../shared/ComponentText';
import { prepareInput } from '../../../utils/InputFieldCongifurations';
import { InputComponent } from '../../shared/FormComponent/InputComponent';
import { ComponentDivider } from '../../shared/ComponentDivider';
import { ComponentIcon } from '../../shared/ComponentIcon';
import Space from '../../shared/Spcae';
import { ComponentButton } from '../../shared/ComponentButton';
import { loadData, putData } from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import { createStore, storeUpdate } from '../Create/AppsActionCreators';
import { ComponentCopyButton } from '../../shared/ComponentCopyButton';
import { BASEURL } from '../../../utils/constants';
import { fonts } from '../../../utils/fonts';
import { removeItem } from '../../../utils/LocalStorage';
import { contentType } from '../../../utils/ActionTypes';
import { Buffer } from 'buffer';

const Styled = {};

Styled.FormCon = styled.div`
  padding: 24px 25px 30px 25px;
  background-color: var(--white);
`;

Styled.AddFileWrapper = styled.div``;

Styled.AddFileTitle = styled.div`
  margin-bottom: 5px;
`;

Styled.AddFileBox = styled.div`
  border: 1px dashed #d0d9df;
  height: 72px;
`;

Styled.AddFileInput = styled.div`
  padding: 20px 150px;
`;

Styled.NotiUrlWrapper = styled.div`
  margin-top: 24px;
`;

Styled.ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

class Settings extends React.Component {

  request = 0;
  constructor(props) {
    super(props);
    this.state = {
      webhook_url:BASEURL,
      name: prepareInput(
        'name',
        'App Name',
        true,
        'Enter App Name',
        'Only used inside Purchasefly',
        'Enter Name'
      ),
      bundle_id: prepareInput(
        'bundle_id',
        'App Bundle ID',
        false,
        'e.g. com.myapp.bundleid',
        'Specified in your Xcode project',
        'Enter Bundle ID',
      ),
      password: prepareInput(
        'password',
        'iTunesConnect App-Specific Shared Secret',
        false,
        'e.g. b43fasd3rfasdr3faefde3r5qaddaf3tfa',
        'Specified in your Xcode project',
        'Enter Secret',
      ),
      notification_url: prepareInput(
        'notification_url',
        'Apple Server Notification URL',
        false,
        'e.g. https://api.purchasefly.com/v3/incoming-notifications/apple-serv',
        '',
        'Enter Apple Server Notification URL',
      ),
      android_package: prepareInput(
        'android_package',
        'Google Play package',
        false,
        'e.g. com.myapp.bundleid',
        'Specified in your apps build.gradle',
        'Enter Package Name',
      ),
      service_credential: prepareInput(
        'service_credential',
        'Service Account credentials JSON',
        false,
        'e.g. {"type": "service_account"...}',
        <a
          href="#"
          // className="eina-semibold-dark-seafoam-green-10"
          style={{
            color: 'var(--dark-sky-blue)',
            width: '84px',
            height: '14px',
            fontFamily: 'SFProDisplay-Medium',
            fontSize: '12px',

            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
          }}
        >
          How to get this?
        </a>,
        'Enter Account',
      ),

    };
    this.p8FileDetails = null;
  }

  componentDidMount () {
    if(this.props.data) {
      this.updateFields(this.props.data);
    }
  }

  handleChange = info => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-2);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    this.setState({ fileList });
  };

  getStore = (data, source) => {

    const stores = {
      appStore: null,
      playStore: null,
    };

    if (data && data.application_store) {
      data.application_store.map(s => {
        if (s.source === 'SOURCE_APP_STORE') {
          stores.appStore = s;
        } else if (s.source === 'SOURCE_PLAY_STORE') {
          stores.playStore = s;
        }
      });
    }

    switch (source) {
    case 'SOURCE_APP_STORE':
      return stores.appStore;
    case 'SOURCE_PLAY_STORE':
      return stores.playStore;
    default:
      return null;
    }
  };

  updateField = (key, value) =>{
    let field = this.state[key];
    field.value = value;
    this.setState({[key]: field });
  };

  updateFields = data =>{
    this.updateField('name', data.name);

    let appStore = this.getStore(this.props.data, 'SOURCE_APP_STORE');
    let playStore = this.getStore(this.props.data, 'SOURCE_PLAY_STORE');
    this.setState({appStore: appStore, playStore: playStore});

    if(appStore !== null){
      this.updateField('bundle_id', appStore.config.appstore_config.bundle_id);
      this.updateField('password', appStore.config.appstore_config.password);
      this.updateField('notification_url', BASEURL+appStore.webhook_url);
      this.setState({webhook_url:BASEURL +appStore.webhook_url.substr(1)});
      //this.updateField('p8_key', appStore.password);

    }
    if(playStore !== null) {
      this.updateField('android_package', playStore.config.playstore_config.package);

      const key = Buffer.from(playStore.config.playstore_config.key, 'base64').toString('ascii');

      this.updateField('service_credential', key);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {data} = this.props;
    if(prevProps.data === null && data !== prevProps.data) {
      this.updateFields(data);
    }
    if(this.props.storeCreatedData && prevProps.storeCreatedData !== this.props.storeCreatedData){
      let appStore = this.getStore(this.props.data, 'SOURCE_APP_STORE');
      let playStore = this.getStore(this.props.data, 'SOURCE_PLAY_STORE');
      if(appStore)
        this.setState({appStore: appStore});
      if(playStore)
        this.setState({playStore: playStore});

    }
    if (this.props.storeCreatedData && this.props.storeCreatedData !== prevProps.storeCreatedData){
      removeItem(contentType.PRODUCT_GROUP_LIST.name);
    }
    if(this.props.postData && prevProps.postData !== this.props.postData){
      if(this.props.postData.appstore_config){
        this.request--;
        if(this.request === 0){
          message.success('Updated Successfully!');
        }
      }

      if(this.props.postData.playstore_config){
        this.request--;
        if(this.request === 0){
          message.success('Updated Successfully!');
        }
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { p8FileDetails } = this;
        const { data} = this.props;
        const { bundle_id, password, android_package, service_credential } = values;
        const {appStore, playStore} = this.state;

        if (bundle_id && password) {

          const appstore_config = {
            bundle_id: bundle_id,
            password: password,
          };

          if (p8FileDetails) {
            const { fileName: f, fileContent: c } = p8FileDetails;
            const p8_key_id = f.substring(f.indexOf('_') + 1, f.indexOf('.'));
            const p8_key = JSON.stringify(c);
            Object.assign(appstore_config, {
              p8_key_id,
              p8_key,
            });
          }

          if(appStore) {
            this.props.onUpdateStore({
              id: appStore.id,
              appstore_config: appstore_config,
              playstore_config: null,
            });
          }else{
            const store = [];
            store.push({
              appstore_config: appstore_config
            });
            this.props.onCreateStore(store, data.id, data.organization_id);
          }
          this.request ++;

        }
        if (android_package && service_credential) {

          let objJsonStr = values.service_credential;
          let serviceCredential = Buffer.from(objJsonStr).toString("base64");


          const playstore_config = {
            package: values.android_package,
            key: serviceCredential,
          };
          if(playStore) {

            this.props.onUpdateStore({
              id: playStore.id,
              appstore_config: null,
              playstore_config: playstore_config,
            });
          }else{
            const store = [];
            store.push({
              playstore_config: {
                package: values.android_package,
                key: JSON.stringify(serviceCredential),
              },
            });
            this.props.onCreateStore(store, data.id, data.organization_id);
          }
        }else if(android_package){
          if(!playStore){
            this.error("Service Account credentials JSON required");
          }
        }
      }
    });
  };

  error = text => {
    if (text === null) return;
    message.error(text);
  };

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    let { isFetching, data, postIsFetching } = this.props;
    const { getFieldDecorator } = this.props.form;

    const styl = {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',
      maxWidth: '650px',
    };


    return (
      <div className="settings-form">
        <Spin spinning={postIsFetching === true}>
          <Form onSubmit={this.handleSubmit} style={styl} layout="vertical">
            <Styled.FormCon>
              {isFetching || !data ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : (
                <InputComponent
                  disabled = {true}
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.name}
                />
              )}

              <ComponentDivider marginTop={20} marginBottom={16} />

              <Row type="flex" justify="start">
                <ComponentIcon type="ios" styl={{ width: 14, height: 16, marginRight: 8 }} />
                <ComponentText
                  text="iOS Configuration"
                  size="14"
                  weight="600"
                  color="var(--greyish-brown)"
                  align="left"
                  marginTop="0"
                  marginBottom="0"
                  styl={{
                    width: '108px',
                    height: '19px',
                    fontFamily: 'SFProDisplay-Semibold',
                    fontSize: '14px',

                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.36',
                    letterSpacing: 'normal',
                    color: '#404040',
                  }}
                />
                <Space width="6" />
              </Row>
              <Space height="20" />
              {isFetching || !data ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : (
                <InputComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.bundle_id}
                />
              )}
              {isFetching || !data ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : (
                <InputComponent
                  form={this.props.form}
                  layout={formItemLayout}
                  values={this.state.password}
                />
              )}

              {isFetching || !data ? (
                <Skeleton active paragraph={{ rows: 8 }} />
              ) : <div>
                <Styled.AddFileWrapper>
                  <Form.Item
                    label="Subscription p8 key file (required for Offers)"
                    style={{ marginBottom: '0px' }}
                  >
                    {getFieldDecorator('dragger', {
                      valuePropName: 'fileList',
                      getValueFromEvent: this.normFile,
                      fileList: this.state.fileList,

                    })(
                      <Upload.Dragger
                        multiple={false}
                        name="p8-file"
                        beforeUpload={file => {
                          const reader = new FileReader();

                          reader.onload = e => {
                            this.setState({p8FileDetails: true});
                            this.p8FileDetails = {
                              fileName: file.name,
                              // save removing last \n (new line)
                              fileContent: e.target.result,
                            // fileContent: e.target.result.replace(/\n$/, ""),
                            };
                          };
                          reader.onerror = e => {
                            console.log('file upload failed!'+e);
                          };

                          reader.readAsText(file);

                          // Prevent upload
                          return false;
                        }}
                        onChange= {this.handleChange}
                      >
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>

                        { this.isP8KeySaved() ? <Row type={"flex"} justify="center" align="middle">
                          <p style={{marginRight:8, fontFamily:'SFProDisplay-Regular', color:'var(--dark-sky-blue)', fontSize:15}} > File Saved. </p>
                          <p style={{fontFamily:'SFProDisplay-Regular', color:'var(--dark-grey-blue)', fontSize:15}}>Add or drag the file here to update</p>
                        </Row>:

                          <Row type={"flex"} justify="center" align="middle">
                            <p style={{marginRight:8, fontFamily:'SFProDisplay-Regular', color:'var(--dark-sky-blue)', fontSize:15}} > Add file </p>
                            <p style={{fontFamily:'SFProDisplay-Regular', color:'var(--dark-grey-blue)', fontSize:15}}>or drag the file here</p>
                          </Row>}



                      </Upload.Dragger>,
                    )}
                  </Form.Item>
                  <ComponentText
                    text="Downloaded when creating a key in App Store Connect. Learn More"
                    align="left"
                    styl={{
                      margin: '5px 0 24px 0',
                      fontFamily: 'SFProDisplay-Medium',
                      fontSize: '13px',

                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: '1.36',
                      letterSpacing: 'normal',
                      color: 'var(--light-grey-blue)',
                    }}
                  />
                </Styled.AddFileWrapper>

                {/*<Styled.NotiUrlWrapper>*/}
                {/*  <InputComponent*/}
                {/*    disabled = {true}*/}
                {/*    form={this.props.form}*/}
                {/*    layout={formItemLayout}*/}
                {/*    values={this.state.notification_url}*/}
                {/*  />*/}
                {/*</Styled.NotiUrlWrapper>*/}

                <Space height={16}/>
                <ComponentText text={"Apple Server Notification URL"} size="13" color="var(--dark)" font={fonts.SFProDisplayMedium} align="left"/>
                <Space height={8}/>
                <Row type="flex" className={"webhook_url"} justify={"space-between"} align={"middle"} >
                  <Col span={22}>
                    <ComponentText text={this.state.webhook_url} size="15" color="var(--slate-grey)" align="left"/>
                  </Col>


                  <Col span={2}>
                    <ComponentCopyButton data={this.state.webhook_url} size={20}/>
                  </Col>

                </Row>


                <ComponentDivider marginTop={20} marginBottom={16} />

                <Row type="flex" justify="start">
                  <ComponentIcon type="android" styl={{ width: 14, height: 16, marginRight: 8 }} />
                  <ComponentText
                    text="Android Configuration"
                    styl={{
                      width: '108px',
                      height: '19px',
                      fontFamily: 'SFProDisplay-Semibold',
                      fontSize: '14px',

                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: '1.36',
                      letterSpacing: 'normal',
                      color: '#404040',
                    }}
                  />
                  <Space width="6" />
                </Row>
                <Space height="24" />
              </div> }

              {isFetching || !data ? (
                <Skeleton active paragraph={{ rows: 4 }} />
              ):(
                <div>
                  <InputComponent
                    form={this.props.form}
                    layout={formItemLayout}
                    values={this.state.android_package}
                  />
                  <InputComponent
                    form={this.props.form}
                    layout={formItemLayout}
                    values={this.state.service_credential}
                  />
                </div>
              )}

            </Styled.FormCon>
            <Space height="24" />

            <Styled.ButtonWrapper>
              {/*<ComponentButton*/}
              {/*  htmlType="submit"*/}
              {/*  name="delete"*/}
              {/*  text="Delete App"*/}
              {/*  css="stroke-salmon eina-semibold-white-15"*/}
              {/*  styl={{*/}
              {/*    width: '164px',*/}
              {/*    height: '42px',*/}
              {/*    borderRadius: '5px',*/}
              {/*    float: 'left',*/}
              {/*  }}*/}
              {/*  stylText={{*/}
              {/*    fontFamily: 'SFProDisplay-Semibold',*/}

              {/*    fontSize: '15px',*/}
              {/*  }}*/}
              {/*/>*/}
              <ComponentButton
                htmlType="submit"
                name="update"
                text="Update"
                css="dodger-blue eina-semibold-white-15"
                styl={{
                  width: '184px',
                  height: '42px',
                  borderRadius: '5px',
                  float: 'right',
                }}
                stylText={{
                  fontFamily: 'SFProDisplay-Semibold',

                  fontSize: '15px',
                }}
              />
            </Styled.ButtonWrapper>
          </Form>
        </Spin>
        <Space height={80}/>
      </div>
    );
  }

  isP8KeySaved = () =>{
    return (this.p8FileDetails && this.p8FileDetails.fileName && this.p8FileDetails.fileContent && this.p8FileDetails) ||
      (this.state.appStore && this.state.appStore.config.appstore_config.p8_key && this.state.appStore.config.appstore_config.p8_key_id);
  }
}

const ComponentSettings = Form.create({ name: 'webhook-create' })(Settings);

const mapActionToProps = {
  onPutData: putData,
  onLoadData: loadData,
  onUpdateStore: storeUpdate,
  onCreateStore: createStore,
};

const mapStateToProps = state => {
  const { storeUpdateRemoteData, storeCreateRemoteData } = state;
  return {
    storeCreatedData: utils.getData(storeCreateRemoteData),
    postData: utils.getItem(storeUpdateRemoteData),
    postIsFetching: utils.getIsFetching(storeUpdateRemoteData),
    postIsFailed: utils.getIsFailed(storeUpdateRemoteData),
  };
};

export default connect(mapStateToProps, mapActionToProps)(ComponentSettings);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 2 },
    sm: { span: 2 },
  },
};

Settings.defaultProps = {
  stores: {
    appStore: null,
    playStore: null,
  },
};
