import React from 'react';
import { connect } from 'react-redux';
import { loadData } from '@/utils/ActionCreators';
import { Link } from 'react-router-dom';
import { Avatar, Col, Divider, Menu, Row, Skeleton, Spin, Tabs } from 'antd';
import { contentType } from '@/utils/ActionTypes';
import { getAppsDetailsPath } from '@/utils/routerUtils';
import { ComponentText } from '@/components/shared/ComponentText';
import utils from '@/utils/utils';
import { ComponentButton } from '@/components/shared/ComponentButton';
import './AppDetails.css';
import ComponentSettings from './ComponentSettings';
import ComponentProductGroup from './ComponentProductGroup';
import ComponentKeys from './ComponentKeys';
import ComponentWebhook from './ComponentWebhook';
import ComponentProducts from './ComponentProducts';
import { removeItem } from '@/utils/LocalStorage';
import Space from '../../shared/Spcae';
import listUtils from '../../../utils/listUtils';

const { TabPane } = Tabs;

class AppsDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = { screenWidth: window.innerWidth - 306 , stores: {SOURCE_APP_STORE: null, SOURCE_PLAY_STORE: null} };
    removeItem(contentType.PRODUCT_GROUP_LIST.name);
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize);
    const { id } = this.props.match.params;
    this.setState({ id: id });
    let { onLoadData } = this.props;

    onLoadData(contentType.APPS, getAppsDetailsPath(id));
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.forceUpdate();
    this.setState({ screenWidth: window.innerWidth - 306 });
  };

  onClick = ({ key }) => {
    let page = listUtils.currentPage(this.props.location.search);
    let limit = listUtils.currentLimit(this.props.location.search);
    this.addQuery({ menu: key, page: page, limit: limit });

  };

  addQuery = query => {
    this.props.history.push({
      pathname: '',
      search: '?' + new URLSearchParams(query).toString(),
    });
  };

  getSelectedMenu = () => {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('menu');
    return token ? token : '0';
  };

  getTitle () {
    const menu = this.getSelectedMenu();
    let title = '';

    switch (menu) {
    case '0':
      title = 'Product Group Settings';
      break;
    case '1':
      title = 'Products Settings';
      break;
    case '2':
      title = 'Webhook Settings';
      break;
    case '3':
      title = 'Settings';
      break;
    default:
      title = 'Keys';
    }
    return title;
  }

  componentWillUpdate (nextProps, nextState, nextContext) {
    if(nextProps.data !== null && this.props.data !== nextProps.data){

      let stores = {SOURCE_APP_STORE: null, SOURCE_PLAY_STORE: null};
      if(nextProps.data && nextProps.data.application_store){
        nextProps.data.application_store.forEach(e => {
          stores[[e.source]] = e.id;
        });
      }

      this.setState({data: nextProps.data, stores: stores});
    }
  }

  render () /**/ {
    let leftMenu = Math.ceil(24 * 185 / this.state.screenWidth);
    let { isFetching, data, isFailed,
      productGroupIsFetching,
      productGroupCreateIsFetching,
      packageCreateIsFetching,
      productCreateIsFetching,
      productActivateIsFetching, } = this.props;
    const { id } = this.props.match.params;

    return (
      <div className="app-details">
        <div className={"group-spinner"}><Spin spinning={
          !!productGroupIsFetching ||
          !!productGroupCreateIsFetching ||
          !!packageCreateIsFetching ||
          !!productCreateIsFetching ||
          !!productActivateIsFetching
        }/></div>
        <div className="app-details-left-menu">
          <Link key='back' to={'/app/list'}>
            <ComponentButton
              name="back"
              iconLeft="previous"
              text="My Apps"
              css="back-app-details"
              iconSize={16}
              height="36"
              width="124"/>
          </Link>
          <Space height={30}/>
          <Col span={leftMenu}>
            <Menu
              className="app-details"
              mode="inline"
              onClick={this.onClick}
              defaultSelectedKeys={[this.getSelectedMenu()]}
              selectedKeys={[this.getSelectedMenu()]}
              style={{ width: 185 }}>

              <Menu.Item key="0">Product Groups</Menu.Item>
              <Menu.Item key="1">Products</Menu.Item>
              <Menu.Item key="2">Webhooks</Menu.Item>
              <Menu.Item key="3">Settings</Menu.Item>
              <Menu.Item key="4">Keys</Menu.Item>
            </Menu>

          </Col>
        </div>

        <Row type="flex">
          <Col span={leftMenu} style={{pointerEvents: "none"}}>
          </Col>
          <Col span={24 - leftMenu} style={{paddingLeft:20}}>
            {this.prepareAppInfo(isFetching, data)}
            <Divider/>
            <Tabs defaultActiveKey="1" tabPosition={'left'}
              activeKey={this.getSelectedMenu()}>
              <TabPane tab={`product group`} key={0}>
                <ComponentProductGroup id={id}
                  stores={this.state.stores}/>
              </TabPane>
              <TabPane tab={`product`} key={1}>
                <ComponentProducts
                  id={id}
                  stores={this.state.stores}
                  history={this.props.history}
                  location={this.props.location}
                />
              </TabPane>
              <TabPane tab={`webhook`} key={2}>
                <ComponentWebhook isFetching={isFetching}
                  isFailed={isFailed}
                  data={data}/>
              </TabPane>
              <TabPane tab={`setting`} key={3}>
                <ComponentSettings stores={this.state.stores} name={data
                  ? data.name
                  : ''} data={this.state.data ? this.state.data: null} isFetching={isFetching}/>
              </TabPane>
              <TabPane tab={`key`} key={4}>
                <ComponentKeys id={id}/>
              </TabPane>
            </Tabs>
          </Col>
        </Row>

      </div>
    );
  }

  prepareAppInfo = (isFetching, data) => {
    return<div style={{height: 86}}>{ isFetching ? <Skeleton width={100} active avatar paragraph={{ rows: 1 }}/> :
      <Row
        type="flex" align="middle">
        <Avatar shape="square" src={data && data.image_url ? data.image_url: "" } size={80}/>
        <Space width="20"/>
        <div>
          <ComponentText
            text={this.getTitle()}
            size="18"
            color="var(--black)"
            font="SFProDisplay-Medium"
            weight="500"
          />
          <Space height={4}/>
          <ComponentText
            text={data && data.name ? data.name : ''}
            styl={{
              fontSize: '18px',
              color: 'var(--slate-grey)',
              fontFamily: 'SFProDisplay-Medium',

              lineHeight: 1.3,
            }}
          />
        </div>

      </Row>}
    </div>;
  }

}

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const { appsRemoteData, productGroupListRemoteData,
    productGroupCreateRemoteData, packageCreateRemoteData,
    productCreateRemoteData, productUpdateRemoteData, productActivateRemoteData } = state;

  return {
    data: utils.getItem(appsRemoteData),
    productGroupData: utils.getItem(productGroupListRemoteData),
    isFetching: utils.getIsFetching(appsRemoteData),
    isFailed: utils.getIsFailed(appsRemoteData),

    productGroupIsFetching: utils.getIsFetching(productGroupListRemoteData),
    productGroupCreateIsFetching: utils.getIsFetching(productGroupCreateRemoteData),
    packageCreateIsFetching: utils.getIsFetching(packageCreateRemoteData),
    productCreateIsFetching: utils.getIsFetching(productCreateRemoteData),
    productUpdateIsFetching: utils.getIsFetching(productUpdateRemoteData),
    productActivateIsFetching: utils.getIsFetching(productActivateRemoteData),
  };
};

export default connect(mapStateToProps, mapActionToProps)(AppsDetails);
