import { message } from 'antd';
import moment from 'moment';

var numeral = require('numeral');
export const ToastTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  WARN: 'warn',
  LOADING: 'loading',
};

export default {
  isLoggedIn(data){
    let loggedIn = true;
    try{
      loggedIn = !!data.token.access_token;
    }catch (e){
      loggedIn = false;
    }
    return loggedIn;
  },
  getItem(object) {
    let data;
    try {
      data = object.response;
    } catch (e) {
      data = null;
    }
    return data;
  },
  getData(object) {
    let data;
    try {
      data = object.response.data;
    } catch (e) {
      data = null;
    }
    return data;
  },
  getMetrics(object) {
    let data;
    try {
      data = object.response.buckets;
    } catch (e) {
      data = null;
    }
    return data;
  },
  getMeta(object) {
    let meta;
    try {
      meta = object.response.meta;
    } catch (e) {
      meta = null;
    }
    return meta;
  },
  getPager(object) {
    let pager;
    try {
      pager = object.response.pager;
    } catch (e) {
      pager = null;
    }
    return pager;
  },
  getName(object) {
    let name;
    try {
      name = object.type;
    } catch (e) {
      name = null;
    }
    return name;
  },
  getIsFetching(object) {
    let isFetching;
    try {
      isFetching = object.isFetching;
    } catch (e) {
      isFetching = false;
    }
    return isFetching;
  },
  getIsFailed(object) {
    let isFailed;
    try {
      isFailed = object.isFailed;
    } catch (e) {
      isFailed = null;
    }
    return isFailed;
  },
  getMessage(object) {
    let message;
    try {
      message = object.message;
    } catch (e) {
      message = null;
    }
    return message;
  },
  prepareDropdownOptions(list) {
    return list
      ? list.map(e => ({
        key: e.id,
        text: e.name,
        value: e.id,
      }))
      : [];
  },
  prepareDropdownOptionsTitle(list) {
    return list
      ? list.map(e => ({
        key: e.id,
        title: e.name,
        value: e.id,
      }))
      : [];
  },
  showToast(content, type, time) {
    time = time ? time : 2;
    switch (type) {
    case ToastTypes.SUCCESS:
      return message.success(content, time);
    case ToastTypes.ERROR:
      return message.error(content, time);
    case ToastTypes.LOADING:
      return message.loading(content, time);
    case ToastTypes.WARN:
      return message.warn(content, time);
    case ToastTypes.WARNING:
      return message.warning(content, time);
    default:
      return message.info(content, time);
    }
  },
  hashCode(str) { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  },
  intToRGB(i) {
    let c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
  },
  getRole(name) {
    switch (name) {
    case 'ROLE_ADMIN':
      return 'Admin';
    case 'ROLE_SUPER_ADMIN':
      return 'Super Admin';
    case 'ROLE_MEMBER':
      return 'Member';

    }
  },
  getProductStatus (status, type) {
    if(type === 'TYPE_CONSUMABLE' )
      return 'One Time Purchase';
    switch (status) {
    case "STATUS_ACTIVE":
      return "Active";
    case "STATUS_READY":
      return "Ready";
    case "STATUS_PENDING":
      return "Pending";
    case "STATUS_UNKNOWN":
      return "Unknown";
    default:
      return status;
    }
  },
  getProductType(type){
    switch (type) {
    case 'TYPE_SUBSCRIPTION':
      return 'Subscription';
    case 'TYPE_CONSUMABLE':
      return 'Consumable';
    default:
      return 'Unknown';
    }
  },
  getSource(source){
    switch (source) {
    case 'SOURCE_APP_STORE':
      return 'App Store';
    case 'SOURCE_PLAY_STORE':
      return 'Play Store';
    default:
      return 'Unknown';
    }
  },
  getFont(font) {
    switch (font) {
    // case 'Roboto':
    //   return 'Roboto-Regular';
    // case 'Roboto-Medium':
    //   return 'Roboto-Medium';
    // case 'Eina-Regular':
    //   return 'Eina-Regular';
    // case 'Eina-Bold':
    //   return 'Eina-Bold';
    case 'SFProDisplay-Regular':
      return 'SFProDisplay-Regular';
    case 'SFProDisplay-Medium':
      return 'SFProDisplay-Medium';
    case 'SFProDisplay-Semibold':
      return 'SFProDisplay-Semibold';
    case 'SFProDisplay-Bold':
      return 'SFProDisplay-Bold';
    default:
      return 'SFProDisplay-Regular';
    }
  },
  formatNumber (num) {
    //return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    //return <NumberFormat value={num} displayType={'text'} thousandSeparator={true} prefix={''} />
    return numeral(num).format('0,0');
  },

  getDefaultDateRange(from, to){
    let now = new Date();
    const currentTime = moment(
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        0,
        0
      )
    );
    return {
      from: from ? from: moment(currentTime).subtract(30, "days").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
      to: to ? to: moment(currentTime).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
    };
  },

  getDateRangeDuration(from, to){
    let start = moment(to, 'YYYY-MM-DDTHH:mm:ssZ');
    let end = moment(from, 'YYYY-MM-DDTHH:mm:ssZ');
    return  start.diff(end, 'days');
  },
  getRandom(min, max) {
    return min + Math.random() * (max - min);
  },
  getDateFromNow(day){

    let now = new Date();
    const currentTime = moment(
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
        0,
        0
      )
    );
    return moment(currentTime).subtract(day, "days").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";

  },
  showError(text){
    message.error(text);
  },

  getErrorText (message) {
    if(typeof message === 'string' || message instanceof String) {
      return message;
    }
    else if(Object.prototype.toString.call(message) === '[object String]'){
      return message.message ? message.message : message.error ? message.error : 'Something went wrong';
    }else
      return 'Something went wrong';
  },
};
