import React from 'react';
import { connect } from 'react-redux';
import { loadData } from '../../../utils/ActionCreators';
import { Avatar, Col, Divider, Row } from 'antd';
import { contentType } from '../../../utils/ActionTypes';
import {
  customerListPath,
  getCustomerDetailsPath,
  getTransactionDetailsPath,
  transactionListPath,
} from '../../../utils/routerUtils';
import { tableContentType } from '../../shared/Table/TableDetails';
import { ComponentText } from '../../shared/ComponentText';
import utils from '../../../utils/utils';
import { ComponentButton } from '../../shared/ComponentButton';
import Space from '../../shared/Spcae';
import { ComponentCopyButton } from '../../shared/ComponentCopyButton';
import { TableDetailsNew } from '../../shared/Table/TableDetailsNew';
import { Link } from 'react-router-dom';
import { localStorageKey } from '../../../utils/LocalStorage';

const title = "Transaction Details";

class TransactionDetails extends React.Component {
  componentDidMount() {
    const {id} = this.props.match.params;
    let {onLoadData} = this.props;
    onLoadData(
      contentType.TRANSACTION,
      getTransactionDetailsPath(id),
    );
  }

  render() {
    let {isFetching, data, isFailed} = this.props;
    const {id} = this.props.match.params;


    const {transaction, application} = data && data.data ? data.data : {};

    const details = transaction ? {purchased_at: transaction.purchased_at,
      id: transaction.id,
      user_id: transaction.user_id +","+ getCustomerDetailsPath(transaction.user_id),
      product: transaction.external_product_id+","+utils.getProductStatus(transaction.status, transaction.type)+","+ (transaction.user_id ? getCustomerDetailsPath(transaction.user_id) : ""),
      revenue: transaction.revenue.toFixed(2).toString(),
      ends_at: transaction.ends_at} : null;

    return (
      <div className={"transaction-details"}>
        {this.goBackToList()}
        <PageHeaderDetails id={id} image={application ? application.image_url: ""}/>
        <Divider/>
        <Row type="flex" align="top" justify="space-between">
          <Col span={24}>
            <ComponentText text={title} size={18} font={"SFProDisplay-Semibold"} color="var(--dark-grey-blue-two)" align="left" marginTop="8" marginBottom="16"/>
            <TableDetailsNew isFailed={isFailed} isFetching={isFetching} data={details} attributes={attributes}/>
          </Col>
        </Row>
      </div>
    );
  }

  goBackToList =() => {
    const {state} = this.props.location;

    let finalSearchParams = new URLSearchParams();
    let key = localStorage.getItem(localStorageKey.SELECTED_APP);
    finalSearchParams.set("application_ids", key ? key : "all-app");
    let env = localStorage.getItem(localStorageKey.SELECTED_ENVIRONMENT);
    const value = env? env : "ENV_PRODUCTION";
    finalSearchParams.set("environment", value);
    finalSearchParams.set("page", "1");

    const to = transactionListPath +"?"+ finalSearchParams.toString();

    return state && state.goBack === true ?    <ComponentButton name="back" iconLeft="previous" text="Transaction List" css="back-app-details" iconSize={16} height="36" handleClick={this.props.history.goBack} />
      : <Link key="back-transaction-list" to={to}>
        <ComponentButton name="back" iconLeft="previous" text="Transaction List" css="back-app-details" iconSize={16} height="36"/>
      </Link>;
  }
}

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const {transactionRemoteData} = state;
  return {
    data: utils.getItem(transactionRemoteData),
    isFetching: utils.getIsFetching(transactionRemoteData),
    isFailed: utils.getIsFailed(transactionRemoteData),
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(TransactionDetails);



function PageHeaderDetails({id, image}) {
  return <Row type="flex" align="middle" style={{marginTop: 30}}>
    <Col>
      <Avatar shape="square" src={image} size={80} icon={'user'} style={{marginRight: 16}}/>
    </Col>

    <Col>
      <ComponentText text="Transaction ID" font={"SFProDisplay-Medium"} size={18} color="var(--black)" align="left" marginTop="0" marginBottom="4"/>
      <Row type="flex" align="middle" >
        <ComponentText text={id} size={18} font={"SFProDisplay-Medium"} color="var(--slate-grey)" align="left" marginTop="0" marginBottom="0"/>
        <Space width="10"/>
        <ComponentCopyButton data={id} size={20}/>
      </Row>
    </Col>
  </Row>;
}

const attributes = [
  {
    title: 'Puchased at',
    field: 'purchased_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'Transaction ID',
    field: 'id',
    type: tableContentType.ID,
  },
  {
    title: 'User ID',
    field: 'user_id',
    type: tableContentType.ID_VIEW,
  },
  {
    title: 'Product',
    field: 'product',
    type: tableContentType.PRODUCT,
  },
  {
    title: 'Revenue',
    field: 'revenue',
    type: tableContentType.PRICE,
  },
  {
    title: 'Expiration at',
    field: 'ends_at',
    type: tableContentType.DATE_TIME,
  },
];