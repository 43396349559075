import React from 'react';
import { connect } from 'react-redux';
import { loadData } from '../../../utils/ActionCreators';
import { Avatar, Col, Divider, Row } from 'antd';
import { contentType } from '../../../utils/ActionTypes';
import {
  customerListPath,
  getCustomerDetails,
  getCustomerProductGroupList,
  getCustomerTotalSpent,
  getTransactionDetails,
} from '../../../utils/routerUtils';
import { CardInfoSmall } from '../../shared/Table/CardInfoSmall';
import { ComponentText } from '../../shared/ComponentText';
import utils from '../../../utils/utils';
import { CardInfoTime } from '../../shared/Table/CardInfoTime';
import { CardInfoPlan } from '../../shared/Table/CardInfoPlan';
import { ComponentButton } from '../../shared/ComponentButton';
import images from '../../../images/Images';
import { ComponentCopyButton } from '../../shared/ComponentCopyButton';
import { tableContentType } from '../../shared/Table/TableDetails';
import { TableListNew } from '../../shared/Table/TableListNew';
import listUtils from '../../../utils/listUtils';
import Space from '../../shared/Spcae';
import { ListEmptyCustomerDetails } from './ListEmptyCustomerDetails';
import { Link } from 'react-router-dom';
import { localStorageKey } from '../../../utils/LocalStorage';

const title = "History";

class CustomerDetails extends React.Component {
  componentDidMount() {
    const {id} = this.props.match.params;
    let {onLoadData} = this.props;

    this.loadCustomerDetails();

    onLoadData(
      contentType.CUSTOMER_TOTAL_SPENT,
      getCustomerTotalSpent(id),
    );
    onLoadData(
      contentType.CUSTOMER_PRODUCT_GROUP_LIST,
      getCustomerProductGroupList(id),
    );
  }

  loadCustomerDetails = () => {
    const {id} = this.props.match.params;
    let {onLoadData} = this.props;
    let page = listUtils.currentPage(this.props.location.search);
    let limit = listUtils.currentLimit(this.props.location.search);

    const filters = [];
    filters['app_user_id'] = id;
    onLoadData(
      contentType.CUSTOMER,
      getCustomerDetails,
      listUtils.prepareActionParams(page, limit, filters),
    );
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    const currentSearch = this.props.location.search;
    const previousSearch = prevProps.location.search;
    if (currentSearch !== previousSearch) {
      this.loadCustomerDetails();
    }
  }

  prepareData = (data) => {
    let user, transactions, pager, lastSeen, firstUsed;
    if(data){
      user = data.user ? data.user : null;
      transactions = data.transactions &&  data.transactions.data ? data.transactions.data : null;
      pager = data.transactions &&  data.transactions.pager ? data.transactions.pager : null;
      lastSeen = user ? user.last_seen_at ? user.last_seen_at : user.created_at : '';
      firstUsed = user ? user.created_at : '';
    }

    return ({user, transactions, pager, lastSeen, firstUsed});

  };

  goBackToList =() => {
    const {state} = this.props.location;

    let finalSearchParams = new URLSearchParams();
    let key = localStorage.getItem(localStorageKey.SELECTED_APP);
    finalSearchParams.set("application_ids", key ? key : "all-app");
    finalSearchParams.set("page", "1");

    const to = customerListPath +"?"+ finalSearchParams.toString();
    return state && state.goBack === true ?    <ComponentButton name="back" iconLeft="previous" text="Customers List" css="back-app-details" iconSize={16} height="36" handleClick={this.props.history.goBack} />
      :  <Link key="back-customer-list" to={to}>
        <ComponentButton name="back" iconLeft="previous" text="Customers List" css="back-app-details" iconSize={16} height="36" />
      </Link>;
  }

  render() {
    let {isFetching, data, isFailed, totalSpent, productGroup, productGroupIsFetching} = this.props;
    const {id} = this.props.match.params;
    const {transactions, pager, lastSeen, firstUsed } = this.prepareData(data);

    const transactionList = transactions ? transactions.map((e)=>{

      return {
        id: e.transaction.id,
        created_at: e.transaction.created_at,
        revenue: "$" + e.transaction.revenue.toFixed(2),
        product_name: e.transaction.external_product_id,
        ends_at: e.transaction.ends_at,
        source: e.transaction.source,
        status: utils.getProductStatus(e.transaction.status, e.transaction.type),
        type: e.transaction.type,
        actions: [
          {
            url: getTransactionDetails(e.transaction.id),
            title: "View",
          },
        ]
      };
    }) : [];

    let showProductGroup = true;

    if(!productGroupIsFetching && productGroup && productGroup.length === 0)
      showProductGroup = false;

    return (
      <div className={'customer-details'}>
        <Row type="flex" justify="space-between" align="middle" style={{paddingRight:8}}>
          {this.goBackToList()}
          {/*<ComponentAppSelect history={this.props.history} location={this.props.location}/>*/}
        </Row>
        <PageHeaderDetails id={id} totalSpent={totalSpent} appId={data ? data.user.application_id: ''}/>
        <Divider/>
        <Row type="flex" align="top" justify="space-between">
          <Col style={{width: "calc(100% - 340px)"}}>
            <ComponentText text={title} font={"SFProDisplay-Semibold"} size="18" color="var(--dark-grey-blue-two)" align="left" marginTop="8" marginBottom="16"/>
            {transactionList && transactionList.length === 0 && isFetching === false ? <ListEmptyCustomerDetails height={window.innerHeight} image={images.transactionEmpty}  title={"transaction"}/>:
              <TableListNew
                history={this.props.history}
                location={this.props.location}
                isLastPage = {!pager}
                list={transactionList}
                isFetching={false}
                isFailed={isFailed}
                attributes={attributes}
                row={3}
              />}
          </Col>

          <Col style={{paddingLeft: 8}}>
            <CardInfoTime lastSeen={lastSeen} firstUsed={firstUsed}/>
            <Space height={8}/>
            { showProductGroup && productGroup && productGroup.length > 0 && productGroup.map((e,i)=> <CardInfoPlan key={i} plan={e}/>)}
            {/*<CardInfoPromotion/>*/}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapActionToProps = {
  onLoadData: loadData,
};

const mapStateToProps = state => {
  const {customerRemoteData, customerTotalSpentRemoteData, customerProductGroupRemoteData} = state;
  return {
    data: utils.getItem(customerRemoteData),
    isFetching: utils.getIsFetching(customerRemoteData),
    isFailed: utils.getIsFailed(customerRemoteData),

    totalSpent: utils.getItem(customerTotalSpentRemoteData),
    productGroup: utils.getData(customerProductGroupRemoteData),
    productGroupIsFetching: utils.getIsFetching(customerProductGroupRemoteData),

  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(CustomerDetails);

function PageHeaderDetails({id, totalSpent, appId}) {
  let image;

  let apps = JSON.parse(localStorage.getItem(localStorageKey.APP_LIST));

  if(apps && appId){
    apps.forEach(e => {
      if(appId === e.id)
        image = e.image_url;
    });
  }


  return <Row type="flex" align="middle" justify="space-between" style={{marginTop: 16}}>
    <Col>
      <Row type="flex" align="middle">
        <Col>
          <Avatar shape="square" src={image} size={80} icon="user" style={{marginRight: 16}}/>
        </Col>

        <Col>
          <ComponentText text="App User ID" font={"SFProDisplay-Medium"} size="18" color="var(--black)" align="left" marginTop="0" marginBottom="4"/>
          <Row type="flex" align="middle" >
            <ComponentText text={id} font={"SFProDisplay-Medium"} size="18" color="var(--slate-grey)" align="left" marginTop="0" marginBottom="0"/>
            <ComponentCopyButton data={id} size={20}/>
            {/*<ComponentButton text="Add Alies" font={"SFProDisplay-Semibold"} css="eina-semibold-dark-seafoam-green-15 text-only" height={20}/>*/}
          </Row>
        </Col>
      </Row>
    </Col>
    <Col>
      <Row type="flex" align="middle">
        <Col>
          <CardInfoSmall icon={images.purchases} title="PURCHASES" count={totalSpent ? totalSpent.total_purchase + "" : ""}/>
        </Col>
        <Col>
          <CardInfoSmall icon={images.totalSpent} title="TOTAL SPENT" count={totalSpent ? "$"+totalSpent.total_spent.toFixed(2) + "" : ""}/>
        </Col>
      </Row>
    </Col>
  </Row>;
}

const attributes = [
  {
    title: '',
    field: 'indicator',
    type: tableContentType.INDICATOR,
  },
  {
    title: 'Date & Time',
    field: 'created_at',
    type: tableContentType.DATE_TIME_CUSTOMER_DETAILS,
  },
  {
    title: 'Product',
    field: 'product_name',
    type: tableContentType.PRODUCT_CUSTOMER_DETAILS,
  },
  {
    title: 'Revenue',
    field: 'revenue',
    type: tableContentType.REVENUE,
  },
  {
    title: 'Transaction ID',
    field: 'id',
    type: tableContentType.POP_OVER,
  },
  // {
  //   title: '',
  //   field: 'action',
  //   type: tableContentType.ACTION,
  // },
  {
    title: '',
    field: '',
    type: tableContentType.EMPTY,
  },
];