import React from 'react';
import { connect } from 'react-redux';
import { message, Row } from 'antd';
import { ComponentText } from '../../shared/ComponentText';
import {
  deleteData,
  loadData,
  postData,
  putData,
} from '../../../utils/ActionCreators';
import utils from '../../../utils/utils';
import Space from '../../shared/Spcae';
import { ComponentButton } from '../../shared/ComponentButton';
import ComponentModalForm from '../../shared/ComponentModalForm';
import { InputTypes } from '../../../utils/InputTypes';
import { prepareInput } from '../../../utils/InputFieldCongifurations';
import { contentType } from '../../../utils/ActionTypes';
import {
  getCollaboratorCreatePath,
  getCollaboratorDeletePath,
  getCollaboratorListPath,
  getCollaboratorResendPath,
  getCollaboratorUpdatePath,
} from '../../../utils/routerUtils';
import { getUser } from '../../../utils/Repository';
import { tableContentType } from '../../shared/Table/TableDetails';
import _ from 'lodash';
import { TableListNew } from '../../shared/Table/TableListNew';
import listUtils from '../../../utils/listUtils';
import { fonts } from '../../../utils/fonts';
import ComponentModalUpdateRole
  from '../../shared/modals/ComponentModalUpdateRole';

const ModalTypes = {
  ADD_COLLABORATOR: 'ADD_COLLABORATOR',
  UPDATE_COLLABORATOR: 'UPDATE_COLLABORATOR',
};
class ComponentCollaborators extends React.Component {

  constructor(props) {
    super(props);
    this.state = {showModalForm: false, showModalUpdateRole: false, collaborator: {},
      list: []};
  }

  componentDidMount() {
    const {onLoadData} = this.props;
    const user = getUser();
    let page = listUtils.currentPage(this.props.location.search);
    let limit = listUtils.currentLimit(this.props.location.search);
    let filters = { organization_ids: user.organizations_roles[0].organization_id };
    onLoadData(
      contentType.COLLABORATOR_LIST,
      getCollaboratorListPath,
      listUtils.prepareActionParams(page, limit, filters)
    );
  }


    addCollaborator = () =>{
      this.setState({showModalForm: true, modalType: ModalTypes.ADD_COLLABORATOR });
    };
    updateCollaborator = (id) =>{
      const list = this.state.list;
      const c = list.find( e => e.id === id);
      this.setState({showModalUpdateRole: true,  modalType: ModalTypes.UPDATE_COLLABORATOR, collaborator: c});
    };
    resendCollaborator = (id) =>{
      const {onPostData} = this.props;
      const user = getUser();
      const response = {
        "type": "RESEND_INVITATION",
        "invitation_req": {
          "user_id": id,
          "organization_id": user.organizations_roles[0].organization_id
        }
      };
      onPostData(contentType.COLLABORATOR_RESEND, getCollaboratorResendPath, response);
      this.onModalClose();
    };
    removeCollaborator = (id) =>{
      const {onDeleteData} = this.props;
      const user = getUser();
      const list = _.cloneDeep(this.state.list);

      list.splice(list.findIndex((e)=> e.id === id), 1);
      this.setState({list: list});
      onDeleteData(contentType.COLLABORATOR_DELETE, getCollaboratorDeletePath(user.organizations_roles[0].organization_id, id));

    };
    onModalClose = (values) =>{
      this.setState({showModalForm: false, showModalUpdateRole: false});
      if(!values)
        return;
      switch (this.state.modalType) {
      case ModalTypes.ADD_COLLABORATOR: {
        const user = getUser();
        const response = {
          invited_user_email: values.email,
          invitation: {
            organization_id: user.organizations_roles[0].organization_id,
            role: values.role
          },
        };
        const { onPostData } = this.props;
        onPostData(contentType.COLLABORATOR_CREATE,
          getCollaboratorCreatePath, response);
      }
        break;
      case ModalTypes.UPDATE_COLLABORATOR:{
        const {onPostData} = this.props;
        const user = getUser();
        const response = {
          organization_id: user.organizations_roles[0].organization_id,
          user_id: values.id,
          role: values.role
        };
        const list = _.cloneDeep(this.state.list);
        for(let i= 0; i<list.length; i++){
          if(list[i].id === values.id){
            list[i].role = utils.getRole(values.role);
            break;
          }
        }
        this.setState({list: list});
        onPostData(contentType.COLLABORATOR_UPDATE, getCollaboratorUpdatePath, response);

      }
        break;
      default:
        break;
      }
    };



    componentDidUpdate(prevProps, prevState, snapshot) {
      let page = listUtils.currentPage(this.props.location.search);
      let limit = listUtils.currentLimit(this.props.location.search);

      let previousPage = listUtils.currentPage(prevProps.location.search);
      let previousLimit = listUtils.currentLimit(prevProps.location.search);

      if(page !== previousPage || limit !== previousLimit){
        this.componentDidMount();
      }

      if (this.props.createIsFailed !== prevProps.createIsFailed && this.props.createIsFailed === true && this.props.createMessage) {
        const text = this.props.createMessage.error.charAt(0).toUpperCase() + this.props.createMessage.error.slice(1);
        utils.showError(text);
      }
      if (this.props.createData !== prevProps.createData && this.props.createData) {
        const e = this.props.createData;
        const status = e.organizations_roles[0].status ? e.organizations_roles[0].status : "";
        const c = {
          id: e.id,
          role : utils.getRole(e.organizations_roles[0].role),
          name : e.name ? e.name : "",
          email: e.email ? e.email: "",
          created_at: e.created_at,
          status: status,
          actions:  [
            {
              url: e.id,
              function: this.resendCollaborator,
              title: "Resend Code",
            }, {
              url: e.id,
              title: "Remove",
              function: this.removeCollaborator
            },
          ]
        };
        const list = _.cloneDeep(this.state.list);
        list.push(c);
        this.setState({list: list});
      }
      if (this.props.list !== prevProps.list && this.props.list) {
        const user = getUser();

        const list = this.props.list.map(e => {
          const status = e.organizations_roles[0].status ? e.organizations_roles[0].status : "";
          return {
            id: e.id,
            role : utils.getRole(e.organizations_roles[0].role),
            name : e.name ? e.name : "",
            email: e.email ? e.email: "",
            created_at: e.created_at,
            status: status,
            actions: user.id === e.id ? [] : [
              {
                url: e.id,
                title: status === "STATUS_INVITED" ? "Resend Code" : "Change Role",
                function: status === "STATUS_INVITED" ? this.resendCollaborator: this.updateCollaborator,
              }, {
                url: e.id,
                title: "Remove",
                function: this.removeCollaborator
              },
            ]
          };
        });
        this.setState({list: list});
      }
      if(this.props.resendData !== prevProps.resendData && this.props.resendData){
        message.success("Invitation Resend");
      }
      if(this.props.collaboratorDeleteData !== prevProps.collaboratorDeleteData && this.props.collaboratorDeleteData){
        message.success("Collaborator Deleted");
      }
      if(this.props.updateData !== prevProps.updateData && this.props.updateData){
        message.success("Collaborator Updated");
      }
    }



    render() {
      let {isFetching, isFailed, pager} = this.props;
      return <div className="invoice">
        <ComponentText text="Collaborators" size="24" color= "var(--black)" font={fonts.SFProDisplaySemiBold}/>

        <Space height={30}/>
        <Row type="flex" justify="end" align="middle">
          <ComponentButton name="add-collaborator" handleClick={this.addCollaborator} iconLeft="add" text="Add Collaborator" css="add-group-item" height="32"/>
        </Row>
        <Space height="20"/>
        <TableListNew
          history={this.props.history}
          location={this.props.location}
          list={this.state.list}
          isLastPage={!pager}
          isFetching={isFetching}
          isFailed={isFailed}
          attributes={attributes}
        />
        <ComponentModalForm visible={this.state.showModalForm} values={modalInput} handleClose={this.onModalClose}/>
        <ComponentModalUpdateRole visible={this.state.showModalUpdateRole} collaborator={this.state.collaborator} handleClose={this.onModalClose}/>
      </div>;
    }
}


const mapActionToProps = {
  onLoadData: loadData,
  onPostData: postData,
  onPutData: putData,
  onDeleteData: deleteData,
};

const mapStateToProps = state => {
  const {collaboratorListRemoteData, collaboratorCreateRemoteData, collaboratorUpdateRemoteData,
    collaboratorDeleteRemoteData, collaboratorResendRemoteData} = state;
  return {
    list: utils.getData(collaboratorListRemoteData),
    pager: utils.getPager(collaboratorListRemoteData),
    isFetching: utils.getIsFetching(collaboratorListRemoteData)
          || utils.getIsFetching(collaboratorCreateRemoteData)
          || utils.getIsFetching(collaboratorUpdateRemoteData)
          || utils.getIsFetching(collaboratorDeleteRemoteData)
          || utils.getIsFetching(collaboratorResendRemoteData),
    isFailed: utils.getIsFailed(collaboratorListRemoteData),

    createData: utils.getItem(collaboratorCreateRemoteData),
    createIsFailed: utils.getIsFailed(collaboratorCreateRemoteData),
    createMessage: utils.getMessage(collaboratorCreateRemoteData),

    updateData: utils.getMessage(collaboratorUpdateRemoteData),
    resendData: utils.getItem(collaboratorResendRemoteData),
    collaboratorDeleteData: utils.getItem(collaboratorDeleteRemoteData),
  };
};
export default connect(
  mapStateToProps,
  mapActionToProps
)(ComponentCollaborators);

const modalInput = {title: "Add Collaborator",
  acceptButtonTitle: "Add Collaborator",
  buttonCancel: true,
  items: [
    {
      type: InputTypes.INPUT,
      value: prepareInput('email', 'Email', true, 'Enter Email', '', 'Enter Email'),
    },
    {
      type: InputTypes.SELECT,
      value: prepareInput('role', 'Role', true, 'Select Role', '', 'Select Role', [{ title: 'Admin', value: 'ROLE_ADMIN' }, { title: 'Member', value: 'ROLE_MEMBER' }]),
    },
  ]};


const attributes = [
  {
    title: '',
    field: 'indicator',
    type: tableContentType.INDICATOR,
  },
  {
    title: 'Name',
    field: 'name',
    type: tableContentType.TEXT,
  },
  {
    title: 'Email',
    field: 'email',
    type: tableContentType.TEXT,
  },

  {
    title: 'Added on',
    field: 'created_at',
    type: tableContentType.DATE_TIME,
  },
  {
    title: 'Role',
    field: 'role',
    type: tableContentType.TEXT,
  },
  {
    title: 'Status',
    field: 'status',
    type: tableContentType.COLLABORATOR_STATUS,
  },
  {
    title: '',
    field: 'action',
    type: tableContentType.ACTION,
  },
  {
    title: '',
    field: '',
    type: tableContentType.EMPTY,
  },
];
